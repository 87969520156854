
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  analysis: path(ROOTS_DASHBOARD, '/analysis'),
  account: path(ROOTS_DASHBOARD, '/account'),
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    account: path(ROOTS_DASHBOARD, '/users/account'),
    new: path(ROOTS_DASHBOARD, '/users/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/users/${id}/edit`),
  },
  data: {
    root: path(ROOTS_DASHBOARD, '/data'),
    new: path(ROOTS_DASHBOARD, '/data/new'),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/data/${id}/edit`),
    experiments: {
      root: path(ROOTS_DASHBOARD, '/data/experiments'),
      new: path(ROOTS_DASHBOARD, '/data/experiments/new'),
      edit: (id: number) => path(ROOTS_DASHBOARD, `/data/experiments/${id}/edit`),
    }
  },
};
