import { createSlice } from '@reduxjs/toolkit';
import { AnalysisState } from '../../@types/analysis';

const initialState: AnalysisState = {
  isLoading: false,
  predictionList: {},
  predictionListParams: {},
  dictModel: [],
  dictForce: [],
  dictWear: [],
};

const slice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getPredictionListSuccess(state, action) {
      state.isLoading = false;
      state.predictionList = action.payload;
    },

    setPredictionListParams(state, action){
      state.predictionListParams = action.payload;
    },

    getDictSuccess(state, action) {
      const data = action.payload.data
      state.dictModel = data.model || [];
      state.dictForce = (data.force as string[]).map(v => +v) || [];
      state.dictWear = (data.wear as string[]).map(v => +v) || [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getPredictionListSuccess, setPredictionListParams, getDictSuccess
} = slice.actions;

