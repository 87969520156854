import { dispatch } from '../store';
import { Experiment, ExperimentList, ExperimentListParams } from '../../@types/experiment';
import ApiClients from '../../utils/axios';
import {
  startLoading,
  hasError,
  getExperimentListSuccess, getExperimentSuccess, editExperimentSuccess,
} from '../slices/experiment';

const { axiosBase } = ApiClients

export function getExperimentListThunk(params: ExperimentListParams) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: ExperimentList } = await axiosBase.get('api/v1/data/experiments', { params });
      dispatch(getExperimentListSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getExperimentThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { data: Experiment } } = await axiosBase.get(`api/v1/data/experiments/${id}`);
      dispatch(getExperimentSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}


export function editExperimentThunk(id: number, params: any) {
  return async () => {
    try {
      const response: { data: { data: Experiment } } = await axiosBase.put(`api/v1/data/experiments/${id}`, params);
      dispatch(editExperimentSuccess(response.data.data))
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  }
}
