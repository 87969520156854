import { dispatch } from '../store';
import { Section, SectionList, SectionListParams } from '../../@types/section';
import ApiClients from '../../utils/axios';
import {
  startLoading,
  hasError,
  getSectionListSuccess,
  addSectionSuccess,
  deleteSectionSuccess, getSectionSuccess, editSectionSuccess,
} from '../slices/section';

const { axiosBase } = ApiClients

export function getSectionListThunk(params: SectionListParams) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: SectionList } = await axiosBase.get('api/v1/data', { params });
      dispatch(getSectionListSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getSectionThunk(id: number) {
  return async () => {
    dispatch(startLoading());
    try {
      const response: { data: { data: Section } } = await axiosBase.get(`api/v1/data/${id}`);
      dispatch(getSectionSuccess(response.data.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createSectionThunk(params: any) {
  return async () => {
    try {
      const response: { data: { data: Section } } = await axiosBase.post(`api/v1/data`, params);
      dispatch(addSectionSuccess(response.data.data))
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  }
}

export function editSectionThunk(id: number, params: any) {
  return async () => {
    try {
      const response: { data: { data: Section } } = await axiosBase.put(`api/v1/data/${id}`, params);
      dispatch(editSectionSuccess(response.data.data))
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  }
}

export function deleteSectionThunk(id: number) {
  return async () => {
    try {
      const response: { data: { data: Section } } = await axiosBase.delete(`api/v1/data/${id}`);
      dispatch(deleteSectionSuccess(response.data.data))
      return true;
    } catch (error) {
      dispatch(hasError(error));
    }
    return false;
  }
}
