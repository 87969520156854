import { createSlice } from '@reduxjs/toolkit';
import { SectionState } from '../../@types/section';
import { ExperimentState } from '../../@types/experiment';

const initialState: ExperimentState = {
  isLoading: false,
  experimentList: {},
  experimentListParams: {},
};

const slice = createSlice({
  name: 'experiment',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getExperimentListSuccess(state, action) {
      state.isLoading = false;
      state.experimentList = action.payload;
    },

    setExperimentListParams(state, action){
      state.experimentListParams = action.payload;
    },

    getExperimentSuccess(state, action) {
      state.isLoading = false;
      state.experiment = action.payload;
    },

    editExperimentSuccess(state, action) {
      state.experimentList = {
        meta: state.experimentList.meta,
        data: state.experimentList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError,
  getExperimentListSuccess, setExperimentListParams,
  getExperimentSuccess, editExperimentSuccess
} = slice.actions;

