// @mui
import { IconButton, TableCell, TableRow } from '@mui/material';
// @types
// components
import { fDate } from '../../../utils/formatTime';
import { Experiment } from '../../../@types/experiment';
import { fShortenNumber } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

type Props = {
  row: Experiment;
  onEditRow: VoidFunction;
};

export default function ExperimentTableRow({
  row,
  onEditRow,
}: Props) {

  const { time, rotation_speed, train_speed, force, angle, removal, width  } = row;

  return (
    <TableRow hover>
      <TableCell align="left">{ fDate(time) }</TableCell>
      <TableCell align="left">{ rotation_speed }</TableCell>
      <TableCell align="left">{ fShortenNumber(train_speed) }</TableCell>
      <TableCell align="left">{ fShortenNumber(force) }</TableCell>
      <TableCell align="left">{ fShortenNumber(angle) }</TableCell>
      <TableCell align="left">{ fShortenNumber(removal) }</TableCell>
      <TableCell align="left">{ fShortenNumber(width) }</TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          onClick={ () => onEditRow() }
        >
          <Iconify icon={'eva:edit-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
