import { createSlice } from '@reduxjs/toolkit';
import { SectionState } from '../../@types/section';

const initialState: SectionState = {
  isLoading: false,
  sectionList: {},
  sectionListParams: {},
};

const slice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getSectionListSuccess(state, action) {
      state.isLoading = false;
      state.sectionList = action.payload;
    },

    getSectionSuccess(state, action) {
      state.isLoading = false;
      state.section = action.payload;
    },

    setSectionListParams(state, action){
      state.sectionListParams = action.payload;
    },

    addSectionSuccess(state, action){
      state.sectionList = {
        meta: state.sectionList.meta,
        data: [action.payload, ...state.sectionList.data || []]
      }
    },

    editSectionSuccess(state, action) {
      state.sectionList = {
        meta: state.sectionList.meta,
        data: state.sectionList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },

    deleteSectionSuccess(state, action){
      state.sectionList = {
        meta: state.sectionList.meta,
        data: state.sectionList.data?.filter(obj => obj.id !== action.payload.id)
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getSectionListSuccess, getSectionSuccess,
  setSectionListParams, addSectionSuccess, editSectionSuccess, deleteSectionSuccess
} = slice.actions;

