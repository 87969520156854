// @mui
import { Container, MenuItem, Stack, TableBody, TextField } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import { DataTable, TableNoData } from '../../components/table';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import { ChangeEvent, useEffect } from 'react';
import { PATH_DASHBOARD } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { setExperimentListParams } from '../../redux/slices/experiment';
import { getExperimentListThunk } from '../../redux/thunks/experiment';
import ExperimentTableRow from '../../sections/data/experimentList/ExperimentTableRow';
import { DEFAULT_LIST_PARAMS } from '../../@types/common';
import { getSectionListThunk } from '../../redux/thunks/section';
import { fDateTime } from '../../utils/formatTime';
import { setSectionListParams } from '../../redux/slices/section';
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
  { id: 'time', label: 'Время', align: 'left', sortable: true },
  { id: 'rotation_speed', label: 'Вращение', align: 'left', sortable: true },
  { id: 'train_speed', label: 'Скорость поезда', align: 'left', sortable: true },
  { id: 'force', label: 'Сила прижатия', align: 'left', sortable: true },
  { id: 'angle', label: 'Угол', align: 'left', sortable: true },
  { id: 'removal', label: 'Съем', align: 'left', sortable: true },
  { id: 'width', label: 'Ширина дорожки', align: 'left', sortable: true },
  { id: '' },
];

export default function ExperinmentList() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sectionList, sectionListParams } = useSelector((state: RootState) => state.section)
  const { experimentList, experimentListParams } = useSelector((state: RootState) => state.experiment)

  useEffect(() => {
    dispatch(setExperimentListParams({
        ...DEFAULT_LIST_PARAMS,
    }));
    dispatch(setSectionListParams({
      ...DEFAULT_LIST_PARAMS,
      full: 1,
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSectionListThunk(sectionListParams))
  }, [dispatch, sectionListParams])

  useEffect(() => {
    dispatch(getExperimentListThunk(experimentListParams))
  }, [dispatch, experimentListParams])

  const handlePageChange = (page: number) => {
    dispatch(setExperimentListParams({
      ...experimentListParams,
      page
    }))
  }

  const handleSortChange = (order_by: string) => {
    const isAsc = experimentListParams.order_by === order_by && experimentListParams.order === 'asc';
    dispatch(setExperimentListParams({
      ...experimentListParams,
      order: isAsc ? 'desc' : 'asc',
      order_by,
      page: 1
    }))
  }

  const handleChangeSection = (e: ChangeEvent<HTMLInputElement>) => {
    const section_id = e.target.value ? +e.target.value : undefined
    dispatch(setExperimentListParams({
      ...experimentListParams,
      section_id,
      page: 0,
    }))
  }

  const handleEditRow = (id: number) => {
    navigate(PATH_DASHBOARD.data.experiments.edit(id));
  };

  return (
    <Page title="Список экспериментов">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Список экспериментов"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Участки пути', href: PATH_DASHBOARD.data.root },
            { name: 'Эксперименты' },
          ]}
        />

        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2 }}>
          <TextField
            fullWidth
            select
            label="Участок пути"
            value={ experimentListParams.section_id || 0 }
            onChange={ handleChangeSection }
            sx={{
              maxWidth: { sm: 220 },
            }}
          >
            <MenuItem value={0}>Все секции</MenuItem>
            {sectionList.data?.map(section => (
              <MenuItem
                key={section.id}
                value={section.id}
              >
                { fDateTime(section.created_at) } - наработка { section.wear }
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <DataTable
          headLabel={ TABLE_HEAD }
          pagination={ experimentList.meta }
          params={ experimentListParams }
          onSort={ handleSortChange }
          onPage={ handlePageChange }
        >
          <TableBody>
            {experimentList.data?.map((row) => (
              <ExperimentTableRow
                key={row.id}
                row={row}
                onEditRow={() => handleEditRow(row.id)}
              />
            ))}

            <TableNoData isNotFound={ experimentList.data?.length === 0 } />
          </TableBody>
        </DataTable>
      </Container>
    </Page>
  );
}
